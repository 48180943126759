import { DateTime } from 'luxon';
import { NativeInputValidation } from '../input';

/**
 * General input validation
 * @param value Input value
 * @returns NativeInputValidation
 */
export const generalValidation = (
	value: string,
	name: string
): NativeInputValidation => {
	let errorMessage = 'Please enter ' + name.toLowerCase();

	if (value.length === 0) {
		errorMessage = name + ' is required';
	}

	const isValid = value.length > 1;

	return {
		value,
		isValid,
		errorMessage: !isValid ? errorMessage : '',
	};
};

/**
 * General input size validation
 * @param value Input value
 * @returns NativeInputValidation
 */
export const sizeValidation = (
	value: string,
	name: string,
	sizeMatch: boolean
): NativeInputValidation => {
	let errorMessage = 'Invalid ' + name.toLowerCase();

	if (value.length === 0) {
		errorMessage = name + ' is required';
	}

	const isValid = sizeMatch;

	return {
		value,
		isValid,
		errorMessage: !isValid ? errorMessage : '',
	};
};

export const expiryCleanUp = (expiryDate: string): string => {
	return expiryDate.replace(/\//g, '');
};

/**
 * Validates card expiry date
 * @param inputDate Expiry date input
 * @returns NativeInputValidation
 */
export const validateExpiryDate = (
	inputDate: string
): NativeInputValidation => {
	// Handle the cleanup of the input date to remove non-numeric characters and any extra slashes
	const cleanDate = expiryCleanUp(inputDate);

	// Get the month and year parts
	let month = cleanDate.substring(0, 2);
	let year = cleanDate.substring(2, 4);

	// Handle deletion of the slash and number following the slash
	if (inputDate.length === 2 && inputDate[inputDate.length - 1] === '/') {
		// If the user deletes the slash, remove the slash and the digit after it
		month = inputDate.substring(0, 1);
		year = '';
	}

	// Format the expiry date in MM/YY format
	const format = `${month}${year ? '/' + year : ''}`;

	// Check if input length is valid and the month part is valid
	const errorMessage =
		inputDate.length === 0
			? 'Expiry date is required'
			: 'Invalid expiry date';

	if (inputDate.length < 3 || month > '12' || format.length !== 5) {
		return {
			value: format,
			isValid: false,
			errorMessage,
		};
	}

	// Parse the date with MM/yy format
	const formattedDate = DateTime.fromFormat(format, 'MM/yy');
	const currentDate = DateTime.local(); // Get the current date

	// Check if the formatted date is in the future
	const isFutureDate =
		formattedDate.year > currentDate.year ||
		(formattedDate.year === currentDate.year &&
			formattedDate.month > currentDate.month);

	return {
		value: formattedDate.isValid ? formattedDate.toFormat('MM/yy') : '',
		isValid: formattedDate.isValid && isFutureDate, // Check both valid date and future date
		errorMessage: formattedDate.isValid
			? isFutureDate
				? ''
				: 'Expiry date must be in the future'
			: errorMessage,
	};
};
